<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ hotel.Name }}飯店銷售庫存管理
      </h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="back"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          回上一頁
        </button>
        <!-- <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button> -->
      </div>
    </div>

    <div class="grid grid-cols-12 gap-2">
      <div class="intro-y col-span-12 lg:col-span-2">
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">現有房型</h2>
          </div>
          <div class="mt-5">
            <!-- <a
              class="flex items-center text-theme-1 dark:text-theme-10 font-medium"
              href=""
            >
              <ChevronRightIcon class="w-4 h-4 mr-2" /> Personal Information
            </a> -->
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md"
              href="javascript:void(0)"
              v-for="room in rooms"
              :key="room.Id"
              :class="{
                ' bg-theme-1 text-white font-medium': current.Id === room.Id,
              }"
              @click="changeItem(room)"
            >
              <ChevronRightIcon class="w-4 h-4 mr-2" /> {{ room.Name }}
            </a>
            <!-- <a class="flex items-center mt-5" href="">
              <LockIcon class="w-4 h-4 mr-2" /> Change Password
            </a>
            <a class="flex items-center mt-5" href="">
              <SettingsIcon class="w-4 h-4 mr-2" /> User Settings
            </a> -->
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-10">
        <div class="intro-y box p-5 mt-5">
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button
              v-show="permission.isCreate"
              class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
              @click="addNew"
            >
              <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
              新增銷售項目
            </button>
          </div>
          <div>
            <div class="mt-2 text-xl">{{ current.Name }}</div>

            <grid
              ref="grid"
              v-bind="gridOptions"
              @addNewRow="
                (row, callback) => {
                  initData(row);
                  callback();
                }
              "
              @edit="onGridEdit"
              @modalResize="onGridModalResize"
              @removeSelectedRows="onGridRemoveSelectedRows"
            >
              <template #modal="{ row, submit, reset }">
                <vxe-form
                  :title-width="100"
                  title-align="right"
                  v-bind="formOptions"
                  :data="row"
                  @reset="reset"
                  @submit="submit"
                >
                </vxe-form>
              </template>
              <template #publish="{ row }">
                <vxe-switch
                  v-model="row.Published"
                  open-label="上架"
                  close-label="下架"
                  :disabled="!permission.isEditing"
                  @change="switchChange(row, $event)"
                ></vxe-switch>
              </template>
            </grid>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="back"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        回上一頁
      </button>
      <!-- <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  Model,
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import formatDate from "xe-utils/toDateString";
import "@/global-components/ckeditor/styles.css";
import VueTagsInput from "@sipec/vue3-tags-input";
import {
  required,
  //   minLength,
  //   maxLength,
  //   email,
  //   url,
  //   integer,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { toRefs, watch, nextTick } from "vue";
import { deepEqual } from "fast-equals";
import _, { truncate } from "lodash";
import { v1 as uuidv1 } from "uuid";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import Grid from "@/cloudfun/components/Grid.vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    FileUploader,
    VueTagsInput,
    SliderEditor,
    SelectBox,
    Grid,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const model = CloudFun.current?.model;
    const hasChanged = ref(false);
    const grid = ref({});
    const original = reactive({ formData: {} });
    const current = ref({});
    const hotel = ref({});
    const masterRow = ref({});
    const rooms = ref({});
    const params = reactive({
      insertRows: [],
      updateRows: [],
      deleteRows: [],
    });
    const form = reactive({
      formData: {
        Id: 0,
        Name: null,
        HotelId: route.query.hotelId,
        Additional: null,
        Notice: {
          Content: "",
        },
        Introduction: {
          Content: "",
        },
        Remark: null,
        PhotoGallery: {
          Pictures: null,
        },
        Specification: {
          Content: "",
        },
      },
    });
    const permission = reactive({ isEditing: true, isCreate: true });

    const gridOptions = {
      title: "房型庫存",
      canCreateRow: true,
      canUpdateRow: true,
      canDeleteRow: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },

      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        // {
        //   field: "ValidStartDate",
        //   title: "有效開始日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "date" },
        //   },
        // },
        // {
        //   field: "ValidEndDate",
        //   title: "有效結束日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "date" },
        //   },
        // },
        {
          field: "Restriction",
          title: "優惠說明",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "StartDate",
          title: "上架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : "",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "date" },
          },
        },
        {
          field: "EndDate",
          title: "下架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : "",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "date" },
          },
        },
        {
          field: "FixedPrice",
          title: "定價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "Price",
          title: "促銷價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "Adult",
          title: "大人人數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "Child",
          title: "小孩人數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "SalesQuantity",
          title: "配售數量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        // {
        //   field: "Promoted",
        //   title: "首頁促銷顯示",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   width: "150",
        //   slots: { default: "promoted" },
        // },
      ],
      promises: {
        query: model
          ? (params) => {
              console.log("params", params);
              if (params.condition)
                params.condition = new Condition(
                  "HotelRoomId",
                  Operator.Equal,
                  current.value.Id ? current.value.Id : 0
                );
              return model.dispatch("hotelRoomSaleItem/query", params);
            }
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("hotelRoomSaleItem/query", {
                keyword: grid.value.keyword,
              })
          : undefined,
        save: model
          ? (params) => {
              if (permission.isEditing || permission.isCreate)
                return model.dispatch("hotelRoomSaleItem/save", params);
              else return Promise.resolve();
            }
          : undefined,
      },
      modalConfig: { height: "Auto", width: "1024" },
    };

    const formOptions = {
      items: [
        {
          field: "Number",
          title: "編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入編號",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Restriction",
          title: "優惠期限說明",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "優惠期限說明",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "ValidStartDate",
          title: "有效開始日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "date",
              placeholder: "有效開始日期",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "ValidEndDate",
          title: "有效結束日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "date",
              placeholder: "有效結束日期",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "StartDate",
          title: "上架日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "date",
              placeholder: "上架日期",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "EndDate",
          title: "下架日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "date",
              placeholder: "下架日期",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "FixedPrice",
          title: "定價",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "integer",
              placeholder: "定價",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Price",
          title: "促銷價",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "integer",
              placeholder: "促銷價",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Adult",
          title: "大人人數",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "integer",
              placeholder: "大人人數",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Child",
          title: "小孩人數",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "integer",
              placeholder: "小孩人數",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "SalesQuantity",
          title: "配售數量",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "integer",
              placeholder: "配售數量",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Published",
          title: "上架",
          span: 12,
          itemRender: {
            name: "$switch",
            props: {
              openLabel: "上架",
              closeLabel: "下架",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入編號",
          },
        ],
      },
    };

    const title = computed(() => {
      return form.formData.Id === 0 ? "新增" : "編輯";
    });

    onBeforeRouteLeave((to, from) => {
      console.log("hasChanged", _.isEqual(original.formData, form.formData));
      if (hasChanged.value) {
        const answer = window.confirm("尚未儲存，確定離開？");
        return answer;
      }
    });

    watch(form.formData, (first, second) => {
      if (!_.isEqual(original.formData, second)) hasChanged.value = true;
    });

    watch(current, (first, second) => {
      console.log("current", current.value);
      grid.value.refresh();
    });

    onMounted(() => {
      if (route.query && route.query.id) {
        const queryParams = {
          condition: new Condition("HotelId", Operator.Equal, route.query.id),
        };
        model?.dispatch("hotelRoom/query", queryParams).then(
          (payload) => {
            rooms.value = payload;
            if (rooms.value.length > 0) current.value = rooms.value[0];
            original.formData = _.cloneDeep(form.formData);
            nextTick(() => {
              hasChanged.value = false;
            });
          },
          (failure) => {
            console.log("failure", failure);
          }
        );
      }
    });

    // modal 執行 新增or修改
    const save = () => {
      validate.value.$touch();
      console.log(validate.value);
      if (validate.value.$invalid) return;
      if (form.formData.Id === 0) params.insertRows = [form.formData];
      else {
        const postData = _.cloneDeep(form.formData);
        if (form.formData.PhotoGallery.length > 0) {
          postData.PhotoGallery = form.formData.PhotoGallery[0];
        }

        params.updateRows = [postData];
      }

      model
        .dispatch("hotelRoom/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            CloudFun.send("info", {
              subject: form.formData.Id == 0 ? "新增成功" : "更新成功",
              content:
                form.formData.Id == 0 ? "飯店資料新增完成" : "飯店資料更新完成",
            });
            hasChanged.value = false;
            router.back();
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally();

      e.preventDefault();
    };

    function changeItem(room) {
      console.log("room", room);
      current.value = room;
    }

    const switchChange = async (row, $event) => {
      const payload = await model?.dispatch("hotelRoomSaleItem/find", row.Id);
      if (payload) {
        payload.Promoted = $event.value;
        await model?.dispatch("hotelRoomSaleItem/update", payload);
      }
    };

    const initData = (row) => {
      row.Id = 0;
      row.Number = "";
      row.Published = true;
      row.HotelRoomId = current.value.Id;
      row.Adult = 2;
      row.Child = 0;
      row.SalesQuantity = 0;
      row.StartDate = new Date();
    };

    const addNew = () => {
      masterRow.value = {
        Id: 0,
        Number: "",
        Published: true,
        HotelRoomId: current.value,
      };

      grid.value.addNewRow(masterRow);
    };

    return {
      switchChange,
      initData,
      changeItem,
      form,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      save,
      router,
      route,
      gridOptions,
      hotel,
      current,
      title,
      permission,
      rooms,
      grid,
      formOptions,
      masterRow,
      addNew,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("hotelRoomSaleItem/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
  },
});
</script>

<style scoped></style>
